.projects-page {
  height: 94vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  .App > div {
    height: 100%;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid white;
    padding: 10px;
    width: 100%;
  }

  .youtube-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: auto;
    height: 40px;
    z-index: 8;
  }
  
  .youtube-icon img {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
  }
  
  .card .youtube-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .year-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 5px;

    button {
      color: var(--secondcolor);
      background-color: var(--buttonproject);
      border: none;
      padding: 10px 15px;
      font-size: 16px;
      border: 1px solid var(--firstcolor);
      cursor: pointer;
      transition: 0.3s ease-in-out;
      flex: 1;
      text-align: center;
      font-family: "Akira Bold";
    }

    .active {
      background-color: var(--firstcolor);
    }
  }

  

  .gallery {
    position: relative;
    width: 100%;
    height: 140vh;
  }

  .gallery-track {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.25rem;
    padding: 0.25rem;
    will-change: transform;
    width: 100%;
  }

  .card {
    position: relative;
    height: 300px;
    overflow: hidden;

    & .card-image-wrapper {
      position: relative;
      height: 100%;
      overflow: hidden;

      &.active-info {
        img {
          opacity: 0.3;
        }
        .info-background {
          display: flex;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease, opacity 0.5s ease;

        &:hover {
          transform: scale(1.1);
        }
      }

      .info-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: none;
        justify-content: center;
        color: white;
        padding: 5px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal;
        font-family: "OpenSans";
        font-size: 1rem;
        text-align: justify;
        line-height: 1.5;
        z-index: 10;

        .info-text {
          font-family: "OpenSans";
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 100%;
          white-space: normal;
          display: block;
          text-align: justify;
          line-height: 1.5;
        }
      }
    }

    .info-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      background-color: rgba(0, 0, 0, 0.9);
      color: white;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      z-index: 900;
      pointer-events: auto;
      font-family: "OpenSans";
      font-weight: 13;

      .tech-used-container {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
        gap: 10px;
      }

      .tech-icons {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: start;

        img {
          width: auto;
          height: 20px;
        }
      }

      .info-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        width: 20px;
        height: auto;
        cursor: pointer;
        transition: transform 0.3s ease;
      }
    }
  }
  
  @media (min-width: 1500px) {
    .info-text {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 1500px) {
    .info-text {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 1200px) {
    .info-text {
      font-size: 0.7rem;
    }
  }
  
  @media (max-width: 860px) {
    .gallery-track {
      grid-template-columns: repeat(2, 1fr);
    }
    .gallery {
      height: 140vh;
    }
    .info-background {
      font-size: 0.9rem;
    }
  }
  @media (max-width: 610px) {
    .year-buttons {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 5px;
    }

    .gallery-track {
      grid-template-columns: repeat(1, 1fr);
    }

    .year-buttons button {
      padding: 10px 10px;
    }
 
    .info-background {
      font-size: 0.8rem;
    }
  }
}
