@font-face {
  font-family: 'Sergoe';
  src: url('../public/Segoe UI.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  src: url('../public/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Akira Bold';
  src: url('../public/Akira\ Bold.ttf') format('truetype');
}

.App > div {
  height: 100%;
}


/* Styling the scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(255, 0, 0);
}

::-webkit-scrollbar-track {
  background-color: rgb(175, 175, 175);
}
.year img, .expimgContainer img {
  height: 50px;
  width: auto;
  transition: transform 0.3s ease;
}

.year img:hover, .expimgContainer img:hover {
  transform: scale(1.2);
}


.about-page {
  height: 96vh; // Utilisez la hauteur de la vue pour permettre le défilement
  overflow-y: auto;
  overflow-x: hidden;

  h1, h2, h3 {
    font-family: "Akira Bold";
  }
  .whiteline {
    width: 840px;
    height: 1px;
    background-color: white;
  }
  .whiteline2 {
    width: 840px;
    height: 1px;
    background-color: white;
    margin-top: 30px;
  }
  main {
    display: flex;
    flex-direction: row;
    color: white;
    height: 145vh;
    z-index: 0;
    
  }


  
  
  
  
  
  .containerleft {
    display: flex;
    flex-direction: column;
  }

  .containerright {
    display: flex;
    flex-direction: column;
    border-left: 1px solid white;
    width: 70%;
  }

  .work h1 {
    margin: 30px 40px;
    font-size: 2.5em;
  }

  .study h1 {
    margin: 0 40px;
    font-size: 2.5em;
  }


  .year {
  display: flex;
  flex-direction: column;
  margin: 30px 40px;
}

.yearbanner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.year img{
  height: 50px;
  width: auto;
}


p {
  font-weight: 100;
  font-family: "OpenSans";
}
  .intro-section {
    display: flex;
    align-items: start;

    .tibo-image {
      width: 150px;
      margin-right: 20px;
    }

    .intro-title {
      flex: 1;
      margin-top: 30px;
    }
  }

  .card {
    position: relative;
    height: 360px;
    min-width: 300px;
    padding: 40px;
    transform-style: preserve-3d;

    .bg {
      z-index: -1;
      top: 0;
      left: 0;
      height: 100%;
      min-width: 100%;
      background: url('./img/tibo.jpg');
      background-size: cover;
      background-position: center;
    }
  }

.secondpart {
  margin: 0 40px;
}

.image-class {
  width: auto;
  height: 70px;
  margin-right: 5px;
}

.expimgContainer {
  margin: 20px 0;
  max-width: 840px;
}
  .titleExp {
    margin-top: 20px;
    text-align: start;
    color: white;
    font-size: 1.9em;
  }

  .introduction {
    width: 80%;

    .intro-title {
      font-size: 2.5em;
      font-family: "Akira Bold";
    }

    .intro-paragraph {
      font-family: "OpenSans";
      font-weight: 100;
      text-align: justify;
      text-align-last: justify;
      hyphens: auto;
      max-width: 584px;
      word-wrap: break-word;
      overflow-wrap: break-word;
      font-size: 18px;
    }
  }

  

  /* Styling the scrollbar */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
  }


  @media screen and (max-width: 1800px) {
    .containerright {
      display: flex;
      flex-direction: column;
      border-left: none;
      width: 100%;
    }
    .yearbanner {
      display: flex;
      flex-direction: row;
      justify-content: start;
      gap: 20px;
    }


    .expimgContainer {
      margin: 20px 0;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    
    .expimgContainer img {
      height: 50px;
      width: auto;
      margin: 5px; /* Espacement entre les images */
    }
    .yearbannertext h2 {
      font-size: 1em;
    }
    main {
      display: flex;
      flex-direction: column;
      color: white;
      height: 275vh;
      z-index: 0;
      
    }
    .yearbanner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
  }

    .titleExp, .work h1, .study h1, p {
      text-align: center;
  }

  .work h1 {
    font-size: 2.5em;
  }

  .titleExp {
    font-size: 1.5em;
  }
    .whiteline {
      width: 380px;
      height: 1px;
      background-color: white;
      margin: 20px auto;
    }
    .whiteline2 {
      width: 380px;
      height: 1px;
      background-color: white;
      margin: 20px auto;
    }
.introduction {
  width: 45%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
    .intro-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      text-align: center;
  
      .tibo-image {
        width: 150px;
        margin-right: 20px;
      }
  
      .intro-title {
        flex: 1;
        margin-top: 0;
      }
    }
  }

  @media screen and (max-width: 1550px) {
    .containerright {
      width: 100%;
    }
  }
  @media screen and (max-width: 1250px) {
    main {
      display: flex;
      flex-direction: column;
      color: white;
      height: 285vh;
      z-index: 0;
      
    }
  }
  @media screen and (max-width: 1000px) {
    main {
      display: flex;
      flex-direction: column;
      color: white;
      height: 295vh;
      z-index: 0;
      
    }
    .intro-section {
      display: flex;
      align-items: start;

    }
    .introduction {
      .intro-paragraph {
        font-family: "OpenSans";
        font-weight: 100;
        text-align: justify;
        text-align-last: justify;
        hyphens: auto;
        max-width: 454px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        font-size: 14px;
      }
    }

    .whiteline {
      width: 710px;
      height: 1px;
      background-color: white;
    }
    .whiteline2 {
      width: 710px;
      height: 1px;
      background-color: white;
      margin-top: 30px;
    }
  
  }

  @media (max-width: 860px) {

    main {
      display: flex;
      flex-direction: column;
      color: white;
      height: 300vh;
      z-index: 0;
      
    }
    .introduction {
      width: 60%;
      .intro-paragraph {
        font-family: "OpenSans";
        font-weight: 100;
        text-align: justify;
        text-align-last: justify;
        hyphens: auto;
        max-width: 389px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        font-size: 12px;
      }
    }

    .whiteline {
      width: 640px;
      height: 1px;
      background-color: white;
    }
    .whiteline2 {
      width: 640px;
      height: 1px;
      background-color: white;
      margin-top: 30px;
    }

    h1 {
      font-size: 2em;
    }
  }

  @media (max-width: 768px) {
    .expimgContainer {
      margin: 20px 0;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    
    .expimgContainer img {
      height: 50px;
      width: auto;
      margin: 5px; /* Espacement entre les images */
    }
    .yearbannertext h2 {
      font-size: 1em;
    }
    main {
      display: flex;
      flex-direction: column;
      color: white;
      height: 310vh;
      z-index: 0;
      
    }
    .yearbanner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
  }

    .titleExp, .work h1, .study h1, p {
      text-align: center;
  }

    .work h1, .study h1 {
      font-size: 1.7em;
    }
  
    .titleExp {
      font-size: 1em;
    }

    .whiteline {
      width: 380px;
      height: 1px;
      background-color: white;
      margin: 20px auto;
    }
    .whiteline2 {
      width: 380px;
      height: 1px;
      background-color: white;
      margin: 20px auto;
    }
.introduction {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
}
    .intro-section {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
  
      .tibo-image {
        width: 150px;
        margin-right: 20px;
      }
  
      .intro-title {
        flex: 1;
        margin-top: 0;
      }
    }


  }

  @media (max-width: 658px) {
    main {
      height: 330vh;
    }
  }
  @media (max-width: 615px) {
    main {
      height: 330vh;
    }
  }
  @media (max-width: 565px) {
    main {
      height: 395vh;
    }
  }
  @media (max-width: 510px) {
    .yearbanner {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .titleExp, .work h1, .study h1 {
      font-size: 0.9em;
    }
    .year img {
      width: 50px;
      height: auto;
    }
    .yearbannertext h2, h3 {
      font-size: 0.7em;
      text-align: center;
    }
    .whiteline {
      width: 200px;
      height: 1px;
      background-color: white;
      margin: 20px auto;
    }
    .whiteline2 {
      width: 200px;
      height: 1px;
      background-color: white;
      margin: 20px auto;
    }
    .expimgContainer {
      margin: 20px 0;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    
    .expimgContainer img {
      height: 30px;
      width: 30px;
      margin: 5px; /* Espacement entre les images */
    }
    

    h1 {
      font-size: 1.5em;
    }
    main {
      height: 435vh;
    }
    .introduction {
      width: 80%;
      .intro-paragraph {
        font-family: "OpenSans";
        font-weight: 100;
        text-align: justify;
        text-align-last: justify;
        hyphens: auto;
        max-width: 397px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        font-size: 10.2px;
      }
      .intro-title {
        font-size: 1.4em;
      }
    }
  }
}
@media (max-width: 390px) {
  main {
    height: 505vh;
  }
}