* {
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  box-sizing: border-box;
}
.loading * {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(0deg);
}
.loading .logo {
  background-image: url("./img/loader.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 180px;
  height: 100%;
}
.loading .dots.animate .dot {
  -webkit-animation: loading-block 3.5s ease-in-out forwards;
          animation: loading-block 3.5s ease-in-out forwards;
}
.loading .dots.animate .dot:after {
  -webkit-animation: loading-dot 3.5s ease-in-out forwards;
          animation: loading-dot 3.5s ease-in-out forwards;
}
.loading .dots .dot {
  width: 300px;
  height: 300px;
}
.loading .dots .dot:after {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  transform: scale(0.17);
}
.loading .dots .dot:nth-child(1) {
  top: 124px;
  left: -228px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.loading .dots .dot:nth-child(1):after {
  transform-origin: top right;
  top: 0;
  right: 0;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.loading .dots .dot:nth-child(2) {
  top: -167px;
  left: -159px;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.loading .dots .dot:nth-child(2):after {
  transform-origin: bottom right;
  bottom: 0;
  right: 0;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.loading .dots .dot:nth-child(3) {
  top: -175px;
  left: -126px;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.loading .dots .dot:nth-child(3):after {
  transform-origin: bottom right;
  bottom: 0;
  right: 0;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.loading .dots .dot:nth-child(4) {
  top: 115px;
  left: 205px;
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
.loading .dots .dot:nth-child(4):after {
  transform-origin: top left;
  top: 0;
  left: 0;
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
.loading .dots .dot:nth-child(5) {
  top: -160px;
  left: 231.5px;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.loading .dots .dot:nth-child(5):after {
  transform-origin: bottom left;
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

@-webkit-keyframes loading-block {
  71.4285714286%, 100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

@keyframes loading-block {
  71.4285714286%, 100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@-webkit-keyframes loading-dot {
  0%, 71.4285714286%, 100% {
    transform: scale(0.17);
  }
  35.7142857143% {
    transform: scale(1);
  }
}
@keyframes loading-dot {
  0%, 71.4285714286%, 100% {
    transform: scale(0.17);
  }
  35.7142857143% {
    transform: scale(1);
  }
}
/* fade in white*/
.fade-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  z-index: 999;
  opacity: 0;
  animation: fade-in-animation 2s ease-in-out forwards;
}

@keyframes fade-in-animation {
  to {
    opacity: 1;
  }
}

/* Loader.css */
.loading.fade-out {
animation: fade-out 1s ease forwards;
}

@keyframes fade-out {
from {
  opacity: 1;
}
to {
  opacity: 0;
}
}
