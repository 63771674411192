.creations-page {
  height: 94vh;
  overflow-y: auto;
  overflow-x: hidden;
  
  .App > div {
    height: 100%;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid white;
    padding: 10px;
    width: 100%;
  }

  .year-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 5px;

    button {
      color: var(--secondcolor);
      background-color: var(--buttonproject);
      border: none;
      padding: 10px 15px;
      font-size: 16px;
      border: 1px solid var(--firstcolor);
      cursor: pointer;
      transition: 0.3s ease-in-out;
      flex: 1;
      text-align: center;
      font-family: "Akira Bold";
    }

    .active {
      background-color: var(--firstcolor);
    }
  }

  @media (max-width: 610px) {
    .year-buttons {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 5px;
    }

    .gallery-track {
      grid-template-columns: repeat(1, 1fr);
    }

    .year-buttons button {
      padding: 10px 10px;
    }

    .info-background {
      font-size: 0.8rem;
    }
  }

  .gallery {
    position: relative;
    width: 100%;
    height: 140vh;
  }

  .gallery-track {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.25rem;
    padding: 0.25rem;
    will-change: transform;
    width: 100%;
  }

  .card {
    position: relative;
    height: 300px;
    overflow: hidden;
    
    & .card-image-wrapper {
      position: relative;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .info-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      background-color: rgba(0, 0, 0, 0.9);
      color: white;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      z-index: 2;
      pointer-events: none;
      font-family: "OpenSans";
      font-weight: 100;

      span, .tech-icons {
        z-index: 3;
      }
      .tech-used-container {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
        gap: 10px;
      }
      .tech-icons {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: start;

        img {
          width: auto;
          height: 20px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    .gallery-track {
      grid-template-columns: repeat(2, 1fr);
    }

    .gallery {
      height: 140vh;
    }
  }

  @media (max-width: 550px) {
    .gallery-track {
      grid-template-columns: repeat(1, 1fr);
      height: 440vh;
    }

    .year-buttons button {
      padding: 10px 10px;
    }
  }
}
