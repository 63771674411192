@font-face {
  font-family: 'Akira Bold';
  src: url('../public/Akira\ Bold.ttf') format('truetype');
}

.App > div {
  height: 100%;
}


.maincontact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;

  img {
    transition: transform 0.3s ease;
  }

  img:hover {
    transform: scale(1.2);
  }

  .containerbloc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .contact-block {
    margin-bottom: 10px;
  }

  .contact-block,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;


    .icons-container {
      display: flex;
      justify-content: center;
    }

    .icon {
      width: 50px;
      height: auto;
      margin-right: 10px;
    }

    h2 {
      font-family: "OpenSans";
      font-weight: 100;
      color: var(--firstcolor);
      margin: 0;

      position: relative;

      &:hover {
        cursor: pointer;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        right: 0;
        right: 0;
        height: 2px; // Hauteur du surlignage
        background-color: var(--firstcolor); // Couleur du surlignage
        transition: width 0.3s; // Transition pour l'animation
        opacity: 0; // Initialement invisible

      }

      &::before {
        top: -5px;
      }

      &::after {
        bottom: -10px;
      }

      &:hover::before,
      &:hover::after {
        width: 100%; // Largeur du surlignage au survol
        opacity: 1; // Rendre le surlignage visible au survol
      }

    }
  }

  .flags {
    width: 50px;
    height: 35px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .maincontact {
    height: 55vh;
  }

}

@media screen and (max-width: 450px) {
  .maincontact {
    h2 {
      font-size: 5vw;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }

}