* {
  margin: 0;
  padding: 0;
}

.display-after-animation.fade-in {
  animation: fade-in-animation 1s ease forwards;
  animation-delay: 0; /* Commencer après le temps total du loader et du fade-out */
  display: block; /* Assurez-vous qu'il est affiché */
}
